<template>
    <span>
        <span class="cursor-pointer">
            <slot v-bind="{ visible, toggle }">
                <i class="fas fa-edit" :class="light ? 'text-white' : 'text-primary'" @click="visible = true"></i>
            </slot>
        </span>

        <el-dialog :title="'Edit ' + Member.full_name"
                   :width="dialogWidth + '%'"
                   :close-on-click-modal="false"
                   :visible.sync="visible">
            <form @submit.prevent="validate()">
                <div class="alert alert-danger" v-if="!isMember">
                  BUE is a NON-MEMBER - User account restricted, including no text message notifications
                </div>
                <div class="row">
                    <div class="col">
                        <fg-input class="no-border"
                                  type="text"
                                  label="First Name"
                                  v-model="Member.fname">
                        </fg-input>
                    </div>
                    <div class="col">
                        <fg-input class="no-border"
                                  type="text"
                                  label="Last Name"
                                  v-model="Member.lname">
                        </fg-input>
                    </div>
                    <div class="col" v-if="is('arearep', area.id)">
                        <label class="mb-0">Area</label>
                        <el-select class="select-default d-block"
                                   placeholder="Area"
                                   v-model="Member.area_id">
                            <el-option v-for="a in areas" :key="a.id" :value="a.id" :label="a.name"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <fg-input class="no-border"
                                  type="tel"
                                  label="Cell Phone"
                                  v-model="Member.cell_phone">
                        </fg-input>
                    </div>
                    <div class="col-md-6 col-sm-12" v-if="Member.role_id">
                        <fg-input class="no-border"
                                  type="email"
                                  label="Email"
                                  name="username"
                                  :class="{'has-danger': errors.has('username')}"
                                  data-vv-as="Email"
                                  v-validate="'required|email'"
                                  v-model="Member.email">
                                  </fg-input>
                        <i v-show="errors.has('username')" class="fas fa-exclamation-triangle"></i>
                        <small v-show="errors.has('username')" class="text-danger">
                            {{ errors.first('username') }}
                        </small>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <label class="mb-0">User Role</label>
                        <el-select class="select-default d-block"
                                   placeholder="User Role"
                                   v-model="Member.role_id"
                                   v-if="Member.role_id && is('arearep', area.id)">
                            <el-option v-for="role in roles"
                                       :key="role.id"
                                       :value="role.id"
                                       :label="role.role" />
                        </el-select>
                        <div v-else>
                            <h4 v-if="Member.role_id" class="font-weight-bold mt-2">{{ roleNames[Member.role_id] }}</h4>
                            <p v-else class="font-italic">Not Registered</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3">
                        <fg-input class="no-border"
                                  type="text"
                                  label="Init"
                                  :maxlength="2"
                                  :disabled="!is('arearep', this.area.id)"
                                  v-model="Member.init">
                        </fg-input>
                    </div>
                    <div class="col">
                        <label class="mb-0">Notify Method</label>
                        <el-select v-if="Member.role_id"
                                   class="select-default d-block"
                                   placeholder="Notification Method"
                                   v-model="Member.notify_method">
                            <el-option value="none" label="None"></el-option>
                            <el-option value="email" label="Email"></el-option>
                            <el-option value="text" label="Text" v-if="facility.allow_text && isLoggedInUser(Member.id) && isMember"></el-option>
                        </el-select>
                        <p v-else class="font-italic">
                            Not Registered
                        </p>
                    </div>
                    <div class="col-md-3 col-sm-12" v-if="is('arearep', area.id)">
                        <label class="mb-0">Controller Type</label>
                        <el-select class="select-default d-block"
                                   placeholder="Controller Type"
                                   v-model="Member.employee_type_id">
                            <el-option v-for="type in employeeTypes" :key="type.id" :value="type.id" :label="type.type_name"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row" v-if="is('arearep', area.id)">
                    <div class="col">
                        <fg-input class="no-border"
                                  type="text"
                                  label="Notes"
                                  v-if="is('arearep', area.id)"
                                  v-model="Member.notes"></fg-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <n-button round type="success" @click.native="validate()" class="mr-2">Save Changes</n-button>
                        <n-button round type="defautl" @click.native="visible = false">Cancel</n-button>
                    </div>
                </div>
            </form>
        </el-dialog>
    </span>
</template>

<script>
import { Dialog, Select, Option, Tooltip } from 'element-ui'
import { mapGetters } from 'vuex'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MemberRepository = RepositoryFactory.get('member')

export default {
  name: 'edit-member',
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip
  },
  props: {
    member: Object,
    light: Boolean
  },
  data () {
    return {
      Member: {},
      saveFields: ['area_id', 'fname', 'lname', 'cell_phone', 'init', 'notes', 'notify_method', 'employee_type_id', 'email', 'password', 'role_id'],
      visible: false,
      changePass: false
    }
  },
  methods: {
    fetchMember () {
      MemberRepository.getMember(this.member.id)
        .then(r => {
          this.Member = r.data
        })
    },
    updateMember () {
      MemberRepository.update(this.Member.id, this.memberFields)
        .then(r => {
          this.$emit('update-member', this.emitChanges(r.data))
          this.visible = false
        })
    },
    emitChanges (updatedMember) {
      const updated = {}
      _.forEach(this.member, (v, k) => {
        if (Object.keys(updatedMember).includes(k) && updatedMember[k] !== v) { updated[k] = updatedMember[k] }
      })
      return updated
    },
    /** Check that fields are valid */
    validate () {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.updateMember()
        }
      })
    },
    toggle () {
      this.visible = !this.visible
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      isLoggedInUser: 'auth/isLoggedInUser',
      facility: 'facility/facility',
      areas: 'area/areas',
      area: 'area/area',
      employeeTypes: 'facility/employeeTypes',
      roleNames: 'auth/roleNames'
    }),
    /** Only save the specified fields from the Member object */
    memberFields () {
      const fields = {}
      const filteredFields = this.Member.user_id ? this.saveFields : _.filter(this.saveFields, f => !['email', 'role_id', 'password'].includes(f))
      _.forEach(this.Member, (f, k) => {
        if (filteredFields.includes(k)) fields[k] = f
      })
      return fields
    },
    dialogWidth () {
      return this.screenSize !== 'lg' && this.screenSize !== 'xl' ? 90 : 50
    },
    roles () {
      return _.filter(_.map(this.roleNames, (role, id) => {
        // Return only roles the user is allowed to assign
        if (this.is('super') || (this.is('arearep', this.area.id) && id !== 'super')) { return { id, role } }
      }))
    },
    isMember () {
      return this.Member.member_status === 'Current Member'
    }
  },
  created () {},
  watch: {
    visible (n) {
      if (n === true && _.isEmpty(this.Member)) this.fetchMember() // Retrieve user if the modal is opened
    }
  }
}
</script>
